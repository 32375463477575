import './Index.css'
import logo from '../../assets/images/logo-full.svg'
import backgroundImage from '../../assets/images/banner.svg'
import slice1 from '../../assets/images/slice1.svg'
import {GradientButton,Button} from '../Common/index'
import Plx from 'react-plx';
import { useMediaQuery } from 'react-responsive'

const parallaxData = [
    {
      start: 0,
      end: 500,
      properties: [
        {
          startValue: 1,
          endValue: 0,
          property: 'opacity',
          easing :'out'
        },
      ],
    },
  ];

function Home ({onClickRightBtn,onClickLeftBtn}){

  const isMobileDevices = useMediaQuery({
    query: "(min-device-width: 410px)",
  });

    return (
            <div className="Header">
                <div className="RowOne">
                   <img src={logo} className='Logo'/>
                   <div className="rowOneTextBox">
                       <h5>One space for all your needs.</h5>
                       <p className="Description">Why look elsewhere when you can manage your brand from your fingertip. 
Manage your products and clients/customers through a single portal.</p>
                       <div style={{paddingTop:10,display: 'flex'}}>
                            <GradientButton name={isMobileDevices ? 'Explore Features' : 'Explore' } onClick={()=>onClickLeftBtn()}/> 
                            <GradientButton name={isMobileDevices ? 'Book Now' : 'Book'} onClick={()=>onClickRightBtn()}/> 

                          
                            {/* <Button name="Book Now" onClick={()=>onClickRightBtn()} /> */}

                        </div>
                   </div>
                   <Plx parallaxData={parallaxData}>
                   <img src={slice1} className='Slice'/>
                   </Plx>

                </div>
                <div className="RowTwo">
                    <div style={{backgroundImage:`url(${backgroundImage})`}} className="Banner">
                        <div className="BannerContent">
                            <h5>About the company</h5>
                            <p className="Description">
                            Single Space is an easy to use Web app that lets you create your free online store and start selling online.Single Space  is a initiative from Affordmate.Affordmate is a tech startup by a bunch of individuals who are Passionate about Technology and the infinite possibilities it creates.As Affordmates,We collaborate and ideate with our customers to bring their vision to reality and that's what thrills us. 
                            </p>
                            {/* <Button name="Book Now" onClick={()=>onClickRightBtn()} /> */}

                        </div>
                   </div>
                </div>
            </div>
    );
}
export default Home;
