import "./index.css";
import { Title, ExploreButton, GradientButton } from "../Common";
import mokupLight from "../../assets/images/sellerspace-2.jpg";
import mokupDark from "../../assets/images/sellerspace-1.jpg";
import Plx from 'react-plx';


const parallaxData = ()=> [
    {
      start: '#mokup-first',
      end: '#mokup-second',
      properties: [
        {
          startValue: .6,
          endValue: 1,
          property: 'scale',
        },
      ],
    },
  ];

  
const parallaxOpacity = ()=> [
    {
      start: '#mokup-first',
      end: '#mokup-second',
      properties: [
        {
            startValue: .5,
            endValue: 1,
            property: 'scale',
          },
        {
          startValue: 150,
          endValue: 1,
          property: 'translateX',
        },
      ],
    },
  ];
  const parallaxVertical = ()=> [
    {
      start: '#mokup-second',
      end: '.end-mockup',
      properties: [
        {
            startValue: 0,
            endValue: 1,
            property: 'scaleY',
          },       
      ],
    },
  ];

  

function MokupFeature({bookNow}) {

    return(
    <div className="">
            <div className="Section MokupWraper">
           

                <div className="MokupRow" id="mokup-first">
                    {/* <div className="MokupRowOne"> */}
                    <Plx
                    className='MokupRowOne'
                    parallaxData={ parallaxData() }
                    >
                        <Title   
                        wraperClass={'mockupTitle'}
                        titleDesClass={'mockupDes'}                       
                        name={'Powefull and Responsive SellerSpace.'}
                         description={'Easy to use, powerful and responsive seller space. \n A space where you can manage your shop, your customers and products. \n Albeit powerful, very user friendly and does not require any sort of technical knowledge whatsoever.'}/>
                    </Plx>
                    <Plx                  
                    parallaxData={ parallaxOpacity() }
                    >
                        <img src={mokupLight} className={'MokupImage'} />
                    </Plx>
                </div>

                <div className="MokupRow" style={{justifyContent:'space-around'}} id="mokup-second">
                    <div className="MokupRowSecond">
                        <img src={mokupDark} className={'MokupImage'} />
                    </div>
                        <Plx   parallaxData={ parallaxVertical() } className={'verticalLine'} />
                    <div style={{alignSelf:'center'}} className={'BookingBtn'}>
                     <Title   
                            textWraper={{ paddingBottom:0,textAlign:'left',justifyContent:'flex-start'}}
                                name={'Be Our Privileged Customer'} />
                            <GradientButton name="Book Now"   onClick={()=>bookNow()}/>                            

                    </div>
                </div>

            </div>
                   <div class="end-mockup"/>
    </div>
    )
}

export default MokupFeature;
