import React from "react";
import HomeScreen from "./components/Banner/Index.js";
import SliderScreen from "./components/Slider/index.js";
import FullFeature from "./components/FullFeature/index.js";
import Features from "./components/Features/index.js";
import Footer from "./components/Footer/index.js";
import MokupFeature from "./components/MokupFeature/index.js";

import {
  Button,
  Form,
  TextBox,
  DropDown,
  Option,
  CheckBox,
  useFormElement,
} from "react-form-elements";

import { Title, Button as PreBook, GradientButton } from "./components/Common";
import "./App.css";
import "./responsive.css";

import ReactModal from "react-modal";
import { useMediaQuery } from 'react-responsive'
import logo from './assets/images/logo.svg'

function App() {

  const isTabletDevice = useMediaQuery({
    query: "(min-device-width: 820px)",
  });

  const [bookNow, setBookNow] = React.useState(false);
  const allFeaturesRef = React.createRef();
  const featureRef = React.createRef();
  const [items, setItems] = React.useState([]);
  const [booking, setBooking] = React.useState(false);
  const [booked, setBooked] = React.useState(false);


  
  //Error States
  const [errorShopName,setErrorShopName] = React.useState(null);
  const [errorEmail,setErrorEmail] = React.useState(null);
  const [errorCatName,setErrorCatName] = React.useState(null);
  const [error,setError] = React.useState(null);
  const [spinner,setSpinner] = React.useState(true);






  const { id, value, handleChange, dropDownRef } = useFormElement(
    "",
    React.createRef()
  );

  // const handleChange = (id,value) => {
  //   console.log('React value',id);
  // }
  React.useEffect(() => {
    fetch("https://www.singlespace.in/app/frontend.php/site/shop-types", {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setItems(result);
          setSpinner(false)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setSpinner(false)
          console.log("Error", error);
        }
      );
  }, []);


    const validateEmail = (email) =>{
    return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };


  //Post Data To server 
  const saveData = (data) =>{
    setBooking(true);
    var prms = new URLSearchParams(data);

    console.log(data);
    fetch("https://www.singlespace.in/app/frontend.php/site/book", {
      method:'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        'Content-Type':'application/x-www-form-urlencoded'
      },
      body:prms,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setBooked(true);
          // setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("Error", error);
        }
      );

  } 

  const handleSubmit = (formData)=>{
    if(booking) return true;
    setErrorShopName(null);
    setErrorEmail(null);
    setErrorCatName(null);
    setError(null);
    let shop_type = formData.shop_type;
    let cat_name = formData.name;

    let email = formData.email;
    

    let error  = null;
    if(shop_type ==""){
      setErrorShopName(true);
      error=true;
    }

    if(email =="" || !validateEmail(email)){
      setErrorEmail(true);
      error=true;
    }

    if(shop_type=='other' && cat_name ==""){
      setErrorCatName(true);
      error=true;
    }

    if(error){
      setError('Plese fill all mandatory fields.');
    }else{
      saveData(formData);
    }

  }
if(spinner){
  return  <div className="App">
    <div className="SpinnerContainer">
    <div className="Spinner">
        <img className={'SpinnerLogo'} src={logo} />
        <h5 className="SpinnerText">Loading...</h5>
    </div>
    </div>
  </div>
}
  return (
    <div className="App">
      <HomeScreen
        onClickRightBtn={() => setBookNow(true)}
        onClickLeftBtn={() =>
          allFeaturesRef.current.scrollIntoView({ behavior: "smooth" })
        }
      />
      <div className="aboutProduct">
      <Title
        name="Create your online store free and easily"
        description={
          "Why limit your sales to your local neighborhood when you can get on SingleSpace and sell throughout the country. Signing up is easy as 1,2,3. Hassle Free. Individuals shops & brands with multiple outlets, we cater to you all. \n Have your online visitors view your shop or brand, see your outlets across different locations (Brands) & Shop from the closest one. Real time product availability communicated across your outlets making it easier for your customer to view and purchase a product of their choice"
        }
      />
      <SliderScreen />
      <FullFeature
        refProp={allFeaturesRef}
        onPress={() =>
          featureRef.current.scrollIntoView({ behavior: "smooth" })
        }
      />
      </div>
      <Features refProp={featureRef} bookNow={()=>setBookNow(true)} />
      <MokupFeature bookNow={()=>setBookNow(true)} />
      <Footer/>
      <ReactModal isOpen={bookNow} className={'BookModal'}>
        <div style={{ display: "flex", justifyContent: "center" }}>

          {
            booked && 
          <div className="BookingForm">

            <div style={{textAlign:'center',justifyContent:'center',height:80+'vh',alignItems:'center',display:'flex'}}>
              <div>
              <h1>Thank you for your booking.</h1> 
              <p className="shortText">
                You don't need to wait as much. We will inform you when its available. </p>
                  <p><b>Together we can build your online E-commerce store. Just HOLD ON</b></p>
              <GradientButton
                  style={{ marginLeft: 30 }}
                  name="Close"
                  onClick={() => setBookNow(false)}
                />
              </div>
            </div>
            </div>


            ||
          <div className="BookingForm">
            <div className="FlexColumn TextCenter">
              <h2>Book Your online Space Now</h2>
              <p className="shortText">We will inform you when its available</p>
             
            </div>
            <Form className="Form"
            onSubmit={handleSubmit}
            >
              <p className="shortText">{error}</p>

              <DropDown
              data-error={errorShopName}
                name="shop_type"
                label="Shop Type *"
                initialValue="0"
                ref={dropDownRef}
                onChange={handleChange}
              >
                <Option initialValue={""}>Select Shop Type</Option>
                {items.map((item) => {
                  return <Option initialValue={item.id}>{item.name}</Option>;
                })}
                <Option initialValue={"other"}>Others</Option>
              </DropDown>

              {value == "other" && (
              
                <TextBox data-error={errorCatName}  name="name" label="Name *" initialValue="" />
              )}
              <TextBox 
              
              data-error={errorEmail}
              name="email" label="Email *" initialValue="" />

              <TextBox
                name="shop_name"
                label="Shop Name (Optional)"
                initialValue=""
              />
              <CheckBox
                className="checkbox"
                label="Send me updates, events and exclusive offers"
                isChecked={true}
                name="send_update"
                value={true}
              />
              <div style={{ display: "flex", justifyContent: "center" }}>
              <Button className={'GbuttonWraper'}>{booking ? 'Booking...' : 'Book Now'}</Button>
                <PreBook
                  style={{ marginLeft: 30 }}
                  name="Close"
                  onClick={() => setBookNow(false)}
                />
              </div>
            </Form>
          </div>
      }
</div>

      </ReactModal>
    </div>
  );
}

export default App;
