import { Title } from "../Common";
import "./index.css";
import bannerOne from "../../assets/images/banner-1.svg";
import largeBox from "../../assets/images/large-box.svg";
import { ExploreButton } from "../Common/index";
import Plx from "react-plx";
import { useMediaQuery } from 'react-responsive'


const parallaxData = [
  {
    start: "#full-features",
    end: "#single-features",
    properties: [
        {
            startValue: 0,
            endValue: 1,
            property: "scale"
          },
          
        
    ],
  },
];

const parallaxTextData = [
    {
      start: "#single-features",
      end: "#feature-two",
      properties: [
      
              {
                startValue: 1,
                endValue: 0,
                property: "opacity"
              }
          
      ],
    },
  ];


  const paralexTitle = [
    {
      start: "#full-features",
      end: "#single-features",
      properties: [
        {
          startValue: .5,
          endValue: 1,
          property: 'scale',
        },
      ],
    },
  ];

function FullFeature({refProp,onPress}) {

  const isTabletDevice = useMediaQuery({
    query: "(min-device-width: 820px)",
  });
  return (
    <div className="Section" id="full-features" ref={refProp}>
      <Plx parallaxData={paralexTitle}>

      <Title
        name="Ease Of Use"
        description={
          "Easy setup - get suggestions about  products which are stored permanently in our database, Waste no time describing products which came back to the market. We fill it out for you."
        }
      />
      </Plx>
      <div className="contentSection">
        <div className="boxOne">
          <div className="FlexRow">
            <div className="LargeTextBox">
           
              
              <Plx parallaxData={parallaxData}>
                <img src={largeBox} className="LargeBox" />
              </Plx>
            
              <Plx parallaxData={parallaxTextData} className="boxWraper">

                <h1 className="LargeTitle">
                  Discover all you can do with SingleSpace
                </h1>
                {
                  isTabletDevice &&
                <ExploreButton onPress={()=>onPress()} />
                }
                </Plx>

            </div>
          </div>
        </div>
        {isTabletDevice && 
        <div className="boxTwo">
          <img src={bannerOne} className="LeftBanner" />
        </div>
        }
      </div>
    </div>
  );
}

export default FullFeature;
