import "./index.css";
import logo from '../../assets/images/affordmate.svg'
import PhoneIcon from '../../assets/images/phone-icon.svg'



function Footer() {
    return(
    <div className="Footer">
         <div className="FooterContent">
             <div className="CompanyRow">
                <p className="FText">SingleSpace</p>
                <p className="Finfo">Single Space is an easy to use Web app that lets you create your free online store and start selling online.Single Space is a initiative from Affordmate.Affordmate is a tech startup by a bunch of individuals who are Passionate about Technology and the infinite possibilities it creates.As Affordmates,We collaborate and ideate with our customers to bring their vision to reality and that's what thrills us.</p>
           </div>
           <div className="CompanyRow RightColumn">
                    <img src={logo} className={'FooterLogo'}/>
                    <div className="ContactRow">
                    <div className="fContact">
                        <img src={PhoneIcon} className={"FIcon"}/>
                        <p href={'tel:919562731131'} className="Finfo">919562731131</p>
                    </div>
                    <div className="fContact">
                        <img src={PhoneIcon} className={"FIcon"}/>
                        <p className="Finfo"> support@singlespace.in</p>
                    </div>
                </div>

           </div>
        </div>
    </div>
    )
}

export default Footer;
