import './index.css'
import Box from '../../assets/images/title-box.svg'
import Arrow from '../../assets/images/arrow.svg'
import Plx from 'react-plx';
import { useMediaQuery } from 'react-responsive'

const parallaxData = (startValue = 0,end=500)=> [
  {
    start: 0,
    end: end,
    properties: [
      {
        startValue: startValue,
        endValue: 1,
        property: 'scale',
      },
    ],
  },
];
function Title({name,description,wraperStyle,textWraper,titleTextClass,imgClass,wraperClass,titleDesClass}){
  const isTabletDevice = useMediaQuery({
    query: "(min-device-width: 820px)",
  });
  return (
  
    <div className={wraperClass} style={wraperStyle}>
        {/* <div className="TitleRow" style={textWraper}> */}
        {
        typeof name!='undefined' &&
          <Plx
          style={textWraper}
          className='TitleRow'
          parallaxData={ parallaxData(.6,300) }
        >

            
            <h4 className={titleTextClass} ><img src={Box}  className={imgClass}/>{name}</h4>
        </Plx>
        }
        {description &&
         <Plx
       

         className='TitleRow'
         parallaxData={ parallaxData(isTabletDevice ? 0 : .7,300) }
       >
           <p   style={textWraper} className={titleDesClass}> 
              {description.split('\n').map((item, i) => <p key={i}>{item}</p>)}
       </p>
        </Plx>

        }
       
    </div>
)}

Title.defaultProps={
  imgClass:'textImage',
  titleTextClass:'Title',
  wraperClass:'TitleWraper',
  titleDesClass:'TitleDescription'
}
const ExploreButton = ({name,onPress}) =>(
    <div className="IconWraper" onClick={()=>onPress()}><img src={Arrow} className="ButtonIcon"/><p className="ExploreText">Explore</p></div>
)

const GradientButton = ({name,onClick}) =>(
    <button className="GbuttonWraper" onClick={()=>onClick()}>
        {name}
    </button>
);

const Button = ({name,onClick,style}) =>(
    <button className="Button" style={style} onClick={()=>onClick()}>
        {name}
    </button>
);


export {Title,GradientButton,Button,ExploreButton}