import { useState } from "react";
import { Title, ExploreButton, GradientButton } from "../Common";
import outlets from "../../assets/images/features/location.svg";
import featureQr from "../../assets/images/features/qr.svg";
import featureSales from "../../assets/images/features/payment.svg";
import featureProductCatelog from "../../assets/images/features/catelog.svg";
import featureReport from "../../assets/images/features/report2.svg";
import { useMediaQuery } from 'react-responsive'
import ReactModal from "react-modal";
import "./index.css";

import Plx from "react-plx";

const FeatureOne = {
  title: "All Your Outlets In One Place",
  description: "Why look elsewhere when you can manage your brand from your fingertip. Manage your products and clients/customers through a single portal.",
  image: outlets,
};
const FeatureTwo = {
  title: "Access Store With QR",
  description: "Every shop is provided a unique QR, once scanned the QR provides product listings, offers and information you would regularly find when you talk to a sales person. \n Hence, the whole process of going into a crowded place disappears completely. \n\n Step 1 - Scan, Step 2- Done.",
  image: featureQr,
};
const FeatureThree = {
  title: "Say no to subscriptions.",
  description: "Our business model takes subscriptions completely out of the equation, \n This provides a fair visibility for every user/shop on the internet, all features and services are given equally to all. Our model revolves around a small fixed percent which comes out of every purchase made by a customer through our app. \n No sales? No payment.",
  image: featureSales,
};
const FeatureFour = {
  title: "A Complete Product catalog",
  description: "Unlike popular websites who show users the same page for every product, with details jumbled across the screen, our app provides you completely tailored experiences for every product. \n We make sure that a prospective buyer gets the product's complete information.",
  image: featureProductCatelog,
};
const FeatureFive = {
  title: "Sales Report",
  description: "A dedicated team analyzing the data and giving you a complete sales report on request/monthly/??whatever \n See your sales numbers, see when it's low, see when it's high. \n Adapt your sales strategy and maximize your income with these reports.",
  image: featureReport,
};

const FeaturesData = [FeatureOne,FeatureTwo,FeatureThree,FeatureFour,FeatureFive]

const parallaxEffect = (start, end) => {
  return [
    {
      start: start,
      end: end,
      properties: [
        {
          startValue: .5,
          endValue: 1,
          property: "scale",
        },
        // {
        //   startValue: 1,
        //   endValue: 0,
        //   property: "scale",
        // },
      ],
    },
  ];
};

function FeatureBox ({ text, wraperStyle, image, onPress, parallaxData,boxId }){ 
  const isTabletDevice = useMediaQuery({
    query: "(min-device-width: 820px)",
  });

  return(
  <div style={wraperStyle} id={boxId}>
    <Plx className="FeatureBoxWraper" parallaxData={parallaxData}>
      <img src={image} className="FeatureImage" />

      <Title
        imgClass={'featureVector'}
        name={text}
        wraperClass={'featureTextWrper'}
        // textWraper={{ justifyContent: "flex-start", alignItems: "center" }}
        titleTextClass={'featureTitle'}
      />
      <ExploreButton onPress={() => onPress()} />
    </Plx>
  </div>
)}
function Features({refProp,bookNow}) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalDetails, setModalData] = useState({
    title: "",
    description: "",
    image: "",
  });

  const isTabletDevice = useMediaQuery({
    query: "(min-device-width: 820px)",
  });


  let value = 0;
  let value1 = 0;

  // if(!isTabletDevice){
  //   value = 400;
  //   value1 = 100;

  // }
  return (
    <div style={{justifyContent:'center',display:'flex'}}>
    <div className="Section Column" id="single-features" ref={refProp}>
      {FeaturesData.map((item,index)=>{
        return (
          <div className={(index%2 != 0) ? 'alignEnd FeatureSection' : 'FeatureSection'} id={'box-'+index}>
        <FeatureBox
        parallaxData={parallaxEffect('#box-'+index, '#box-'+(index+1))}

          onPress={() => {
            setModalData({
              title: item.title,
              description: item.description,
              image: item.image,
            });
            setIsOpen(true);
          }}
          text={item.title}
          image={item.image}
        />
      {((FeaturesData.length-1) == index) &&   <div id={'box-'+(index+1)} /> }
      </div>
        )
      })}
      



      <ReactModal isOpen={modalIsOpen}>
        <div onClick={() => setIsOpen(false)} className="CloseIcon">
          <h5>Close</h5>
        </div>
        <div className="Modal">
          <div className="ModalBox">
            <img src={modalDetails.image} className="ModalImage" />
          </div>
          <div className="ModalBoxTwo">
            <Title
              name={modalDetails.title}
              wraperStyle={{ paddingBottom: 0 }}
            />
            <p className="Description" style={{ lineHeight: 2 }}>
              {/* {modalDetails.description} */}
              {modalDetails.description.split('\n').map((item, i) => <p key={i}>{item}</p>)}

            </p>
            <GradientButton name={"Book Now"} onClick={()=>bookNow()} />
          </div>
        </div>
      </ReactModal>
    </div>
    </div>
  );
}

export default Features;
