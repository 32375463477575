import React from 'react'
import './index.css'
import { StackedCarousel, ResponsiveContainer, StackedCarouselSlideProps } from 'react-stacked-center-carousel';
import mockupOne from '../../assets/images/screens/screen-1.png'
import mockupTwo from '../../assets/images/screens/screen-2.png'
import mockupThree from '../../assets/images/screens/screen-3.png'
import mockupFour from '../../assets/images/screens/screen-4.png'
import mockupFive from '../../assets/images/screens/screen-5.png'
import { useMediaQuery } from 'react-responsive'

const data = [
    {cover: mockupTwo}, 
    {cover: mockupThree},
  {cover: mockupOne},
  {cover: mockupFive},
{cover: mockupFour},

];

function ResponsiveCarousel() {
    
const isTabletDevice = useMediaQuery({
    query: "(min-device-width: 1200px)",
  });

    // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
    // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
    // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
    // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
    const ref = React.useRef();
    return (
      <div style={{ width: '100%', position: 'relative' }}>
            <ResponsiveContainer carouselRef={ref} render={(parentWidth, carouselRef) => {
                        let currentVisibleSlide = 5;
                        if (parentWidth <= 1440) currentVisibleSlide = 3;
                        else if (parentWidth <= 1080) currentVisibleSlide = 1;
                        return (
                            <StackedCarousel
                                    ref={carouselRef}
                                    data={data}
                                    carouselWidth={parentWidth}
                                    slideWidth={300}
                                    slideComponent={Slide}
                                    maxVisibleSlide={5}
                                    currentVisibleSlide={currentVisibleSlide}
                                    // useGrabCursor={true}
                                    // disableSwipe={isTabletDevice ? false : true}
                            />
            )}}/>
        
      </div>
    );
}

// Very important to memoize your component!!!
// Also very imporant to set draggable to false on your slide if you want to use swipe!!!
const Slide = React.memo(
    function (props: StackedCarouselSlideProps) {
        const { data, dataIndex } = props;
        const { cover } = data[dataIndex];
        return (
            <div style={{width: '100%', height: 600, userSelect: "none"}}>
                <img
                    style={{height: '100%', width: '100%', objectFit: 'cover', borderRadius: 10}}
                    draggable={false}
                    src={cover}
                />
            </div>
        );
    }
);

export default ResponsiveCarousel;